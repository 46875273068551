import {Auditor, Service} from '@/constants/permissions'

export const routes = [
  {
    path:      '/',
    name:      'Root',
    component: () => import(/* webpackChunkName: "Layout" */ '@/views/DefaultLayout.vue'),
    hidden:    true,
    meta:      {
      auth:     true,
      redirect: permission => {
        switch (permission) {
          case Auditor:
            return 'Auditor';
          case Service:
            return 'Service';
          default:
            return null;
        }
      },
    },
    children:  [
      {
        path:      "/revizor",
        name:      "Auditor",
        component: () => import(/* webpackChunkName: "Auditor" */ '@/views/pages/PageAuditor'),
        meta:      {
          title:       'Revízor',
          root:        true,
          auth:        true,
          keepAlive:   true,
          permissions: [Auditor]
        }
      },
      {
        path:      "/servis",
        name:      "Service",
        component: () => import(/* webpackChunkName: "Service" */ '@/views/pages/PageService'),
        meta:      {
          title:       'Servis',
          root:        true,
          auth:        true,
          keepAlive:   true,
          permissions: [Service]
        }
      }
    ]
  },
  {
    path:      '/prihlasenie',
    name:      'Login',
    component: () => import(/* webpackChunkName: "AuthLogin" */ '@/views/auth/AuthLogin.vue'),
    meta:      {
      title: 'Prihlásenie'
    }
  },
  {
    path:      '/oops',
    name:      'Oops',
    component: () => import(/* webpackChunkName: "Oops" */ '@/views/error/ErrorOops.vue'),
    meta:      {
      only:  true,
      title: 'Oops...'
    }
  },
  {
    path:      '*',
    name:      'NotFound',
    component: () => import(/* webpackChunkName: "Oops" */ '@/views/error/ErrorOops.vue'),
    meta:      {
      only:  true,
      title: 'Stránka sa nenašla'
    }
  },
];
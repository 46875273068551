import Vue from 'vue';
import store from '@/store';
import LocalStorageToken from "@/services/storage/local/token";

const LocalStorageTokenService = LocalStorageToken.getService();

export default function execute() {
  // Request Token
  Vue.prototype.$http.interceptors.request.use(
    config => {
      const token = LocalStorageTokenService.getAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    error => Promise.reject(error));

  // Response Token
  Vue.prototype.$http.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      const originalRequest = error.config;
      if (!originalRequest.url.startsWith('/oauth')
        && error.response
        && error.response.status === 401
        && !originalRequest._retry
      ) {
        const refresh = LocalStorageTokenService.getRefreshToken();
        if (refresh) {
          originalRequest._retry = true;
          return store.dispatch("auth/refresh").then(() => Vue.prototype.$http(originalRequest));
        }

        return store.dispatch("auth/logout", false);
      }
      return Promise.reject(error);
    }
  );
}

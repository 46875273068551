import LocalStorageToken from '@/services/storage/local/token'
import Vue from 'vue'

const LocalStorageTokenService = LocalStorageToken.getService();
export default {
  namespaced: true,
  state:      {
    token: LocalStorageTokenService.getAccessToken() || '',
  },
  getters:    {
    isAuthenticated: state => !!state.token
  },
  mutations:  {
    success(state, token) {
      state.token = token;
      LocalStorageTokenService.setAccessToken(token);
    },
    logout(state) {
      state.token = null;
      LocalStorageTokenService.clearToken();
    }
  },
  actions:    {
    login({commit, dispatch}, credentials) {
      return Vue.prototype.$http.post(
        process.env.VUE_APP_API_AUTH_PATH,
        {
          grant_type:    'password',
          client_id:     process.env.VUE_APP_API_AUTH_CLIENT_ID,
          client_secret: process.env.VUE_APP_API_AUTH_SECRET_ID,
          scope:         process.env.VUE_APP_API_AUTH_GLOBAL_SCOPE,
          username:      credentials.username,
          password:      credentials.password
        }
      ).then(response => {
        const data = response.data;
        commit('success', data.access_token);
        LocalStorageTokenService.setRefreshToken(data.refresh_token);
        return dispatch('user/get', null, {root: true});
      });
    },
    refresh({commit, dispatch}) {
      return Vue.prototype.$http.post(
        process.env.VUE_APP_API_AUTH_PATH,
        {
          grant_type:    'refresh_token',
          refresh_token: LocalStorageTokenService.getRefreshToken(),
          client_id:     process.env.VUE_APP_API_AUTH_CLIENT_ID,
          client_secret: process.env.VUE_APP_API_AUTH_SECRET_ID,
          scope:         process.env.VUE_APP_API_AUTH_GLOBAL_SCOPE,
        }
      ).then(response => {
        const data = response.data;
        commit('success', data.access_token);
        LocalStorageTokenService.setRefreshToken(data.refresh_token);
      }).catch(() => dispatch('logout'));
    },
    logout({commit}, request = true) {
      const promise = request
        ? Vue.prototype.$http.get('api/logout')
        : Promise.resolve();

      return promise
        .then(() => {
          commit('logout');
        })
        .then(() => window.location.assign('/'));
    },
  }
}
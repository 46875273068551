import Vue from 'vue'

export default {
  namespaced: true,
  state:      {
    id:          null,
    email:       null,
    name:        null,
    surname:     null,
    permissions: [],
    provider:    {},
    audit:       {
      device: null,
      status: null
    },
    stats:       {}
  },
  mutations:  {
    push(state, data) {
      state.id          = data.id;
      state.email       = data.email;
      state.name        = data.name;
      state.permissions = data.permissions;
    },
    pushProvider(state, data) {
      if (!data.provider) {
        return;
      }

      state.provider.id   = data.provider.id;
      state.provider.name = data.provider.name;
    },
    pushStats(state, data) {
      state.stats = data;
    },
    pushAudit(state, data) {
      // Remove
      if (!data) {
        state.audit = {
          device: null,
          status: null
        }
        return;
      }
      // Revision Device
      if (data.device) {
        state.audit = {
          ...state.audit,
          device: {
            id:   data.device.id,
            name: data.device.name,
            type: data.device.device_type,
            lock: typeof data.device.lock === 'string' ? Vue.prototype.moment(data.device.lock) : data.device.lock
          }
        }
      }

      // Revision Status
      if (data.status && data.status.end === null) {
        state.audit = {
          ...state.audit,
          status: {
            lock:  typeof data.status.start === 'string' ? Vue.prototype.moment(data.status.start) : data.status.start,
            stats: {
              checked:  parseInt(data.status.checked) || 0,
              reported: parseInt(data.status.reported) || 0,
            }
          }
        }
      }
    },
    plusAuditChecked(state) {
      if (state.audit?.status?.stats?.checked) {
        state.audit.status.stats.checked++;
      }
    },
    plusAuditReported(state) {
      if (state.audit?.status?.stats?.reported) {
        state.audit.status.stats.reported++;
      }
    }
  },
  getters:    {
    has:         state => !!state.id,
    email:       state => state.email,
    name:        state => state.name,
    permissions: state => state.permissions || [],
    provider:    state => state.provider || {},
    audit:       state => state.audit || {},
    stats:       state => state.stats || {},
  },
  actions:    {
    get({commit}) {
      return Vue
        .prototype.$http.get('/api/user')
        .then(response => {
          commit('push', response.data.data);
          commit('pushProvider', response.data.data);
          commit('pushAudit', {
            device: response.data.data.revision_device || null,
            status: response.data.data.last_revision_status || null
          });
          if (Object.prototype.hasOwnProperty.call(response.data.data, 'stats')) {
            commit('pushStats', response.data.data.stats);
          }
          return response;
        });
    },

    reload({commit}) {
      return Vue
        .prototype.$http.get('/api/user')
        .then(response => {

          // Audit
          if (response.data.data.revision_device === null) {
            commit('pushAudit');
          }
          else {
            commit('pushAudit', {
              device: response.data.data.revision_device || null,
              status: response.data.data.last_revision_status || null
            });
          }

          // Stats
          if (Object.prototype.hasOwnProperty.call(response.data.data, 'stats')) {
            commit('pushStats', response.data.data.stats);
          }
          return response;
        });
    },

    audit({commit}, data) {
      commit('pushAudit', data);
    },
    auditStatsChecked({commit}) {
      commit('plusAuditChecked');
    },
    auditStatsReported({commit}) {
      commit('plusAuditReported');
    }
  },
}
